$background_color_2: #ddd;
$color_5: #000;
$font_family_1: "Lato", sans-serif;

.thankyou {
  header {
    .logo {
      height: 60px;
    }
  }

  section {
    padding: 100px 0px;

    h5 {
      font-size: 40px;
      font-weight: 700;
    }
  }

  .fixd-ftr {
    position: fixed;
    width: 100%;
    bottom: 0px;
  }

  @media (max-width: 767px) {
    .fixd-ftr {
      position: relative;
    }
  }

  footer {
    font-family: $font_family_1 !important;
    background-color: $background_color_2;
    text-align: center;
    .ft-logo {
      img {
        height: 60px;
      }
    }
    img {
      height: 115px;
    }
    ul {
      li {
        padding: 0 5px;
        a {
          text-decoration: none;
        }
      }
    }
    p {
      font-size: 18px;
    }
    a {
      color: $color_5 !important;
    }
  }
}
